import "./App.css";
import { useEffect } from "react";
import { useTelegram } from "./hooks/useTelegram";
import { Route, Routes } from "react-router-dom";

import CreateOrderPage from "./pages/CreateOrderPage/CreateOrderPage";
import UpdateOrderPage from "./pages/UpdateOrderPage/UpdateOrderPage";
import Register from "./pages/Register";
import ShowOrdersPage from "./pages/ShowOrdersPage/ShowOrdersPage";
import CurrenciesPage from "./pages/CurrenciesPage/CurrenciesPage";
import FaqsPage from "./pages/FaqsPage/FaqsPage";
import ReceiptPage from "./pages/ReceiptPage/ReceiptPage";
import ExchangePage from "./pages/ExchangePage/ExchangePage";
import ShowExchangeOrdersPage from "./pages/ShowExchangeOrdersPage/ShowExchangeOrdersPage";
import ExchangeReceiptPage from './pages/ExchangeReceiptPage/ExchangeReceiptPage'
import DeliveryReceiptPage from './pages/DeliveryReceiptPage/DeliveryReceiptPage'

function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path={"order"} element={<CreateOrderPage />} />
        <Route path={"order/:orderId"} element={<UpdateOrderPage />} />
        <Route path={"register"} element={<Register />} />
        <Route path={"orders/:orderId"} element={<ShowOrdersPage />} />
        <Route path={"currency"} element={<CurrenciesPage />} />
        <Route path={"faq"} element={<FaqsPage />} />
        <Route path={"orders/:orderId/receipt"} element={<ReceiptPage />} />
        <Route path={"exchange"} element={<ExchangePage />} />
        <Route path={"exchange/:exchangeId"} element={<ShowExchangeOrdersPage />} /> 
        <Route path={"exchange/:orderId/receipt"} element={<ExchangeReceiptPage />} />  
        <Route path={"order/:orderId/delivery-receipt"} element={<DeliveryReceiptPage />} />  
      </Routes>
    </div>
  );
}

export default App;
